import React from 'react';
import LoadingPage from './app/common/loading/LoadingPage';

const Top = () => {
  const [App, setApp] = React.useState(false);

  React.useEffect(() => {
    const promise = import('./App');
    promise.then((result) => {
      setApp(() => result.AppWithStore);
    });
  }, []);

  if (!App) return <LoadingPage />;

  return <App />;
};

export default Top;
